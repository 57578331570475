/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Paths
import {
  procurementNavBarConfig,
  PATH_APP_AUTH,
  PATH_APP_MAIN,
  PATH_APP_PROCUREMENT,
} from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const CreateRequestPage = Loadable(lazy(() => import('src/pages/requests/create/index')));
const ManageRequestPage = Loadable(lazy(() => import('src/pages/requests/manage/index')));
// const ReportRequestPage = Loadable(lazy(() => import('src/pages/requests/reports')));
// const AnalyticsRequestPage = Loadable(lazy(() => import('src/pages/requests/analytics')));
const RequestsManagementDashboardPage = Loadable(lazy(() => import('src/pages/requests/dashboard/index')));
const PagePermissionDenied = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
const PageMaintenance = Loadable(lazy(() => import('src/pages/maintenance')));
const ProcurementManageRequestsPage = Loadable(lazy(() => import('src/pages/procurement/manage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Request routes list, contains list of RouteItem type objects.
 */
const procurementRoutes: TypeRouteItem = {
  path: PATH_APP_PROCUREMENT.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <PlatformAccessGuard path={PATH_APP_MAIN.root}>
        <DashboardLayout navConfig={procurementNavBarConfig} />
      </PlatformAccessGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_PROCUREMENT.requests.rootFull} replace />, index: true },
    // Applications
    {
      path: PATH_APP_PROCUREMENT.requests.root,
      element: <ProcurementManageRequestsPage />
    },
  ]
};

export default procurementRoutes;
