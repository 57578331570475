/**
 * @description - The number into proper format methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Numeric module
import numeral from 'numeral';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

export function fCurrency(number: string | number, unit?: string) {
  let unitCharacter;
  switch (unit) {
    case 'usd':
      unitCharacter = '$';
      break;
    case 'amd':
      unitCharacter = '֏';
      break;
    case 'eur':
      unitCharacter = '€';
      break;
    case 'gbp':
      unitCharacter = '£';
      break;
    case 'rub':
      unitCharacter = '₽';
      break;
    default:
      unitCharacter = '$';
      break;
  }
  return numeral(number).format(Number.isInteger(number) ? `${unitCharacter}0,0` : `${unitCharacter}0,0.00`);
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? `0,0` : `0,0.00`);
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
