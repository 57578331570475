/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeBudget, TypeBudgetLine } from 'src/@types';
// API services
import {
  fetchBudgetsTotalNumberByCompanyUid,
  fetchBudgetsByCompanyUid,
  fetchBudgetByBudgetUid,
  fetchBudgetLinesByBudgetUid,
  updateBudgetLine,
  updateBudget,
} from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type BudgetsState = {
  isBudgetLoading: boolean;
  error: Error | string | null;
  totalBudgets: number;
  budgets: TypeBudget[];
  budget: TypeBudget | null;
  budgetLines: TypeBudgetLine[] | null;
  success: string | null;
};

/**
 * @description - The initialization of the new request form state
 */
const budgetsInitialState: BudgetsState = {
  isBudgetLoading: false,
  error: null,
  totalBudgets: 0,
  budgets: [],
  budget: null,
  budgetLines: null,
  success: null,
}

/**
 * @description - The slices for the new request form
 */
const budgetSlice = createSlice({
  name: 'budgets',
  initialState: budgetsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isBudgetLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isBudgetLoading = false;
      state.error = action.payload;
    },
    // GET TEAM PROJECTS
    getBudgetsSuccess(state, action) {
      state.isBudgetLoading = false;
      state.budgets = action.payload;
    },
    getBudgetsTotalNumberSuccess(state, action) {
      state.isBudgetLoading = false;
      state.totalBudgets = action.payload;
    },
    // GET PLATFORM's PROJECTS
    getBudgetSuccess(state, action) {
      state.isBudgetLoading = false;
      state.budget = action.payload;
    },
    // GET PLATFORM's PROJECTS
    getBudgetLinesSuccess(state, action) {
      state.isBudgetLoading = false;
      state.budgetLines = action.payload;
    },
    // createEmployeeSuccess(state, action) {
    //   state.isLoading = false;
    //   state.created = true;
    // },
    updateBudgetLineSuccess(state) {
      state.isBudgetLoading = false;
      state.success = 'Updated successfully';
    }
  }
});
// The reducers
export default budgetSlice.reducer;

// State base actions
export const {} = budgetSlice.actions;

/**
 * @description - The method is fetching new request forms from the server
 */
export function getBudgetsList(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      fetchBudgetsByCompanyUid(
        companyUid,
        (budgetsList) => dispatch(budgetSlice.actions.getBudgetsSuccess(budgetsList)),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
        orderByCategory,
        categoryOrderSort,
        limitNumber,
        pageNumber,
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching the total number of the members
 */
export function getBudgetsTotalNumber(companyUid: string) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      fetchBudgetsTotalNumberByCompanyUid(
        companyUid,
        (totalNumber) => dispatch(budgetSlice.actions.getBudgetsTotalNumberSuccess(totalNumber)),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}

export function getBudgetByUid(uid: string) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      fetchBudgetByBudgetUid(
        uid,
        (budgetData) => dispatch(budgetSlice.actions.getBudgetSuccess(budgetData)),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}

export function getBudgetLinesByUid(uid: string) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      fetchBudgetLinesByBudgetUid(
        uid,
        (budgetLinesData) => dispatch(budgetSlice.actions.getBudgetLinesSuccess(budgetLinesData)),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}

export function updateBudgetLineByUid(budgetUid: string, budgetData: Record<string, any>[]) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      updateBudgetLine(
        budgetUid,
        budgetData,
        () => dispatch(budgetSlice.actions.updateBudgetLineSuccess()),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}

export function updateBudgetByUid(budgetUid: string, budgetData: Record<string, any>) {
  return () => {
    dispatch(budgetSlice.actions.startLoading());
    try {
      updateBudget(
        budgetUid,
        budgetData,
        () => dispatch(budgetSlice.actions.updateBudgetLineSuccess()),
        (error) => dispatch(budgetSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(budgetSlice.actions.hasError(error));
    }
  }
}
