/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeDepartment } from 'src/@types';
// API services
import { fetchDepartmentsForCompany, fetchDepartmentByUid, updateDepartmentDataByUid, createDepartmentByCompanyUid } from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type DepartmentsState = {
  isLoading: boolean;
  error: Error | string | null;
  departments: TypeDepartment[];
  department: TypeDepartment | null;
  message: {
    text: string,
    type: 'error' | 'success',
  } | null;
};

/**
 * @description - The initialization of the new request form state
 */
const departmentsInitialState: DepartmentsState = {
  isLoading: false,
  error: null,
  departments: [],
  department: null,
  message: null,
}

/**
 * @description - The slices for the new request form
 */
const departmentsSlice = createSlice({
  name: 'departments',
  initialState: departmentsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.message = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.message = null;
    },
    // GET PLATFORM's PROJECTS
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload;
    },
    getDepartmentByUidSuccess(state, action) {
      state.isLoading = false;
      state.department = action.payload;
    },
    updateDepartmentSuccess(state) {
      state.isLoading = false;
      state.message = {
        text: 'Department updated successfully',
        type: 'success',
      }
    }
  }
});
// The reducers
export default departmentsSlice.reducer;

// State base actions
export const {} = departmentsSlice.actions;

/**
 * @description - The method is fetching new request forms from the server
 */
export function getDepartments(companyUid: string) {
  return () => {
    dispatch(departmentsSlice.actions.startLoading());
    try {
      fetchDepartmentsForCompany(
        companyUid,
        (departmentsList) => dispatch(departmentsSlice.actions.getDepartmentsSuccess(departmentsList)),
        (error) => dispatch(departmentsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(departmentsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching new request forms from the server
 */
export function getDepartmentByUid(departmentUid: string) {
  return () => {
    dispatch(departmentsSlice.actions.startLoading());
    try {
      fetchDepartmentByUid(
        departmentUid,
        (department) => dispatch(departmentsSlice.actions.getDepartmentByUidSuccess(department)),
        (error) => dispatch(departmentsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(departmentsSlice.actions.hasError(error));
    }
  }
}

export function updateDepartmentByUid(departmentUid: string, departmentData: Partial<TypeDepartment>) {
  return () => {
    dispatch(departmentsSlice.actions.startLoading());
    try {
      updateDepartmentDataByUid(
        departmentUid,
        departmentData,
        () => {
          dispatch(departmentsSlice.actions.updateDepartmentSuccess());
          dispatch(getDepartmentByUid(departmentUid));
        },
        (error) => dispatch(departmentsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(departmentsSlice.actions.hasError(error));
    }
  }
}

export function createDepartment(companyUid: string, departmentData: Partial<TypeDepartment>) {
  return () => {
    dispatch(departmentsSlice.actions.startLoading());
    try {
      createDepartmentByCompanyUid(
        companyUid,
        departmentData,
        (departmentUid) => {
          dispatch(departmentsSlice.actions.updateDepartmentSuccess());
          dispatch(getDepartmentByUid(departmentUid));
        },
        (error) => dispatch(departmentsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(departmentsSlice.actions.hasError(error));
    }
  }
}
