/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { updateDoc, getDocs, addDoc, getDoc, Timestamp, writeBatch } from 'firebase/firestore';
// Queries
import {
  queryGetBudgetsListByCompanyUid,
  queryGetActiveBudgetsTotalNumberByCompanyUid,
  getNewBudgetReference,
  queryGetBudgetLinesByBudgetUid,
  getBudgetReference,
  getBudgetLineReference,
  getDb,
} from './queries';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export type Budget = {
  display_name: string;
  description: string | null;
  uid: string;
  manager_uid: string;
  status: string;
  created: Timestamp;
  updated: Timestamp;
  balance: number;
  unit: 'amd' | 'usd' | 'gbp' | 'rub' | 'eur';
}

export type BudgetLine = {
  display_name: string;
  description: string;
  uid: string;
  status: string;
  balance: number;
  unit: 'amd' | 'usd' | 'gbp' | 'rub' | 'eur';
}

/**
 * @description - The method is getting total number of the budgets
 * @param company_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetsTotalNumberByCompanyUid(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: Error | string) => void,
) {
  // Generating query
  const queryTotalNumber = queryGetActiveBudgetsTotalNumberByCompanyUid(company_uid);
  if (queryTotalNumber) {
    getDocs(queryTotalNumber)
      .then((snapshotTotalNumber) => onSuccess(snapshotTotalNumber.size))
      .catch((error) => onFail(error.message))
  }
}

/**
 * @description - The method is fetching budgets list data for the company
 * @param company_uid
 * @param onSuccess
 * @param onFail
 * @param orderByCategory
 * @param categoryOrderSort
 * @param limitNumber
 * @param pageNumber
 */
export function fetchBudgetsByCompanyUid(
  company_uid: string,
  onSuccess: (budgets: Budget[]) => void,
  onFail: (error: Error | string) => void,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // Generating query
  const queryDetails = queryGetBudgetsListByCompanyUid(company_uid);
  if (queryDetails) {
    getDocs(queryDetails)
      .then((budgetsSnapshots) => {
        const budgets: Budget[] = [];
        budgetsSnapshots.forEach((budgetSnapshot) => {
          const budgetData = budgetSnapshot.data();
          if (budgetData) {
            budgets.push({
              uid: budgetData.uid,
              display_name: budgetData.display_name,
              description: budgetData.description,
              manager_uid: budgetData.manager_uid,
              status: budgetData.status ?? 'suspended',
              created: budgetData.created,
              updated: budgetData.updated,
              balance: budgetData.balance || 0,
              unit: budgetData.unit || 'amd',
            });
          }
        });
        onSuccess(budgets);
      })
      .catch((error) => onFail(error.message));
  }
}

/**
 * @description - The method is fetching budget lines list data for the budget
 * @param budget_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetLinesByBudgetUid(
  budget_uid: string,
  onSuccess: (budgetLines: BudgetLine[]) => void,
  onFail: (error: Error | string) => void,
) {
  // Generating query
  const queryDetails = queryGetBudgetLinesByBudgetUid(budget_uid);
  if (queryDetails) {
    getDocs(queryDetails)
      .then((budgetLinesSnapshots) => {
        const budgetLines: BudgetLine[] = [];
        budgetLinesSnapshots.forEach((budgetSnapshot) => {
          const budgetLineData = budgetSnapshot.data();
          if (budgetLineData) {
            budgetLines.push({
              uid: budgetLineData.uid,
              display_name: budgetLineData.display_name,
              description: budgetLineData.description,
              status: budgetLineData.status ?? 'suspended',
              balance: budgetLineData.balance || 0,
              unit: budgetLineData.unit || 'usd',
            });
          }
        });
        onSuccess(budgetLines);
      })
      .catch((error) => onFail(error.message));
  }
}

/**
 * @description - The method is fetching budget's detailed view
 * @param budgetUid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetByBudgetUid(
  budgetUid: string,
  onSuccess: (budget: Budget) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getBudgetReference(budgetUid);
  getDoc(queryDetails)
    .then((budgetSnapshot) => {
      if (budgetSnapshot.exists()) {
        const budgetData = budgetSnapshot.data();
        if (budgetData) {
          onSuccess({
            uid: budgetData.uid,
            display_name: budgetData.display_name,
            description: budgetData.description,
            manager_uid: budgetData.manager_uid,
            status: budgetData.status ?? 'suspended',
            created: budgetData.created,
            updated: budgetData.updated,
            balance: budgetData.balance || 0,
            unit: budgetData.unit || 'amd',
          });
        } else {
          onFail(`Something went wrong during fetching budget data ${budgetUid}`);
        }
      } else {
        onFail(`Budget data does not exist ${budgetUid}`);
      }
    })
    .catch((error) => onFail(error.message))
}

export function updateBudgetLine(
  budgetUid: string,
  budgetData: Record<string, any>[],
  onSuccess: () => void,
  onFail: (error: Error | string) => void,
) {
  const batch = writeBatch(getDb());
  budgetData.map((budget) => {
    const query = getBudgetLineReference(budgetUid, budget.uid);
    batch.update(query, {
      balance: budget.balance,
      display_name: budget.display_name,
      description: budget.description,
      status: budget.status,
      unit: budget.unit || 'usd'
    });
  });
  batch.commit().then(onSuccess).catch(onFail);
}

export function updateBudget(
  budgetUid: string,
  budgetData: { display_name?: string, manager_uid?: string },
  onSuccess: VoidFunction,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getBudgetReference(budgetUid);
  updateDoc(queryDetails, { ...budgetData }).then(onSuccess).catch(onFail);
}
