import { TypePlatforms, TypeCurrency, TypeLanguageModel } from "src/@types";

export const LOADER_SHORT_TIME = 5000;
export const LOADER_LONG_TIME = 10000;
export const CURRENCIES_OPTIONS: TypeCurrency[] = [
  { iso_code: 'amd', symbol: '֏', display_name: 'Դրամ'},
  { iso_code: 'usd', symbol: '$', display_name: 'U.S. Dollar'},
  { iso_code: 'eur', symbol: '€', display_name: 'Euro'},
  { iso_code: 'gbp', symbol: '£', display_name: 'Pound sterling'},
  { iso_code: 'rub', symbol: '₽', display_name: 'Рубль'},
  { iso_code: 'cny', symbol: '¥', display_name: '元'},
];
export const MONTHS_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const DEFAULT_REQUEST_LIST_BY_PLATFORM: {
  platform: TypePlatforms,
  display_name: string,
}[] = [
  { platform: 'REQUEST', display_name: 'PO/Requests' },
  // { platform: 'HR', display_name: 'HR' },
  // { platform: 'DOCUMENT', display_name: 'Contracts/Acts' },
]
export const DEFAULT_DOCUMENT_LANGUAGES: TypeLanguageModel[] = [
  { display_name: 'English', display_name_original: 'English', code: 'en' },
  { display_name: 'Armenian', display_name_original: 'Հայերեն', code: 'hy' },
  { display_name: 'Russian', display_name_original: 'Русский', code: 'ru' },
]

type SocialMediaItem = {
  value: string,
  name: string,
  icon: string,
  color: string,
  path: string | null,
}

export const DEFAULT_SOCIALS: SocialMediaItem[] = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: null,
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: null,
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: null,
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
    path: null,
  },
];

interface SocialMediaProps {
  facebook: string | null,
  instagram: string | null,
  linkedIn: string | null,
  twitter: string | null,
}

export function GENERATE_DEFAULT_SOCIALS({ facebook, instagram, linkedIn, twitter }: SocialMediaProps): SocialMediaItem[] {
  return [
    {
      value: 'facebook',
      name: 'FaceBook',
      icon: 'eva:facebook-fill',
      color: '#1877F2',
      path: facebook || null,
    },
    {
      value: 'instagram',
      name: 'Instagram',
      icon: 'ant-design:instagram-filled',
      color: '#E02D69',
      path: instagram || null,
    },
    {
      value: 'linkedin',
      name: 'Linkedin',
      icon: 'eva:linkedin-fill',
      color: '#007EBB',
      path: linkedIn || null,
    },
    {
      value: 'twitter',
      name: 'Twitter',
      icon: 'eva:twitter-fill',
      color: '#00AAEC',
      path: twitter || null,
    },
  ]
}
