/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeEmployeeProfile, TypeEmployeeProfileWithGroups } from 'src/@types';
// API services
import {
  fetchTeamTotalNumberByCompanyUid,
  fetchTeamByCompanyUid,
  fetchTeamMemberByUserUid,
  updateEmployeeByUid,
  createEmployee,
} from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type EmployeesState = {
  isLoading: boolean;
  error: Error | string | null;
  success: string | null;
  totalEmployees: number;
  employees: TypeEmployeeProfile[];
  member: TypeEmployeeProfile | null;
};

/**
 * @description - The initialization of the new request form state
 */
const employeesInitialState: EmployeesState = {
  isLoading: false,
  error: null,
  success: null,
  totalEmployees: 0,
  employees: [],
  member: null,
}

/**
 * @description - The slices for the new request form
 */
const teamSlice = createSlice({
  name: 'team',
  initialState: employeesInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = null;
    },
    // GET TEAM PROJECTS
    getTeamSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload;
    },
    getTeamTotalMembersNumberSuccess(state, action) {
      state.isLoading = false;
      state.totalEmployees = action.payload;
    },
    // GET PLATFORM's PROJECTS
    getEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.member = action.payload;
    },
    updateUserSuccess(state) {
      state.isLoading = false;
      state.success = 'Employee updated successfully.'
    },
    createEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.success = 'Employee created successfully.'
    },
  }
});
// The reducers
export default teamSlice.reducer;

// State base actions
export const {} = teamSlice.actions;

// ================================================================================================================== //
// =================================================== ENDPOINTS ==================================================== //
// ================================================================================================================== //

/**
 * @description - The method is fetching the total number of the members
 */
export function getTeamMembersTotalNumber(companyUid: string) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      fetchTeamTotalNumberByCompanyUid(
        companyUid,
        (employeesTotalNumber) => dispatch(teamSlice.actions.getTeamTotalMembersNumberSuccess(employeesTotalNumber)),
        (error) => dispatch(teamSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching new request forms from the server
 */
export function getTeamMembersList(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      fetchTeamByCompanyUid(
        companyUid,
        (employeesList) => dispatch(teamSlice.actions.getTeamSuccess(employeesList)),
        (error) => dispatch(teamSlice.actions.hasError(error)),
        orderByCategory,
        categoryOrderSort,
        limitNumber,
        pageNumber,
      );
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The endpoint is creating the member
 * @param data
 */
export function createMember(data: Partial<TypeEmployeeProfileWithGroups>) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      createEmployee(
        data,
        (employee) => dispatch(teamSlice.actions.createEmployeeSuccess(employee)),
        (error) => dispatch(teamSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The endpoint is removing the user employment info
 * @param uid
 * @param companyUid
 * @param onSuccess
 */
export function deleteTeamMemberByUid(uid: string, companyUid: string, onSuccess: VoidFunction) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      updateEmployeeByUid(
        uid,
        { active: false },
        () => {
          dispatch(teamSlice.actions.updateUserSuccess());
          getTeamMembersTotalNumber(companyUid);
          onSuccess();
        },
        (error) => dispatch(teamSlice.actions.hasError(error)),
      )
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is updating team member info
 * @param uid
 * @param data
 */
export function updateTeamMemberByUid(
  uid: string,
  data: Partial<TypeEmployeeProfile>,
) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      updateEmployeeByUid(
        uid,
        { ...data },
        () => {
          dispatch(teamSlice.actions.updateUserSuccess());
          window.location.reload();
        },
        (error) => dispatch(teamSlice.actions.hasError(error)),
      )
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching team member
 * @param uid
 */
export function getTeamMemberByUid(uid: string) {
  return () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      fetchTeamMemberByUserUid(
        uid,
        (employee) => dispatch(teamSlice.actions.getEmployeeSuccess(employee)),
        (error) => dispatch(teamSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  }
}
