/**
 * @description - The Firebase Auth Context to provide authentication listener.
 * ToDo - BIIG ToDo optimize this file
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from 'react';
// Firebase instance type
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
// Firestore
import { Timestamp } from 'firebase/firestore';
// Services
import { getUserPrivateProfile, getUserEmployeeProfile, getCompanyProfileByUid, updateUserByUid } from 'src/services';
// Notification bar
import { useSnackbar } from 'notistack';
// Local storage data
import { useLocalStorage } from 'src/hooks';
import { TypeEmployeeProfile, TypeAccountProfile } from 'src/@types';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - User Auth Type
 */
export type AuthUser = null | Record<string, any>;

/**
 * @description - Common Reducer Action Types that maps reducer payload and type
 * into object type for the use.
 */
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

/**
 * @description - Enumeration for initialization
 */
enum Initialisation {
  Initial = 'INITIALISE'
}

/**
 * @description - The firebase Authentication Payload type
 */
type FirebaseAuthPayload = {
  [Initialisation.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

/**
 * @description - Firebase Module's based Reducer Action Type
 */
export type FirebaseAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

/**
 * @description - The Firebase Actions Type
 */
export type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

/**
 * @description - Firebase Auth Context Type
 * @property isAuthenticated - Contains boolean of user authenticated status
 * @property isInitialized - Contains boolean of the user authentication process
 * status
 * @property login - Firebase login-password-password functionality
 * @property signWithEmailLink - Firebase login-via email link functionality
 * @property register - Firebase register functionality
 * @property logout - Firebase logout functionality
 * @property resetPassword - Firebase reset function functionality
 */
export type FirebaseAuthContext = {
  // Authentication status
  isAuthenticated: boolean;
  isInitialized: boolean;
  // User based methods
  login: (email: string, password: string) => Promise<void>;
  signWithEmailLink: (email: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (data: Record<string, any>) => void;
  // User profiles
  companies?: string[];
  admin?: string[];
  user?: TypeAccountProfile;
  userProfile?: TypeAccountProfile,
  employeeProfile?: TypeEmployeeProfile;
};

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Initial state for the Firebase Auth State that is passing into
 * reducer
 */
const initialState: FirebaseAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

/**
 * @description - Firebase Authentication Module based Redux's Reducer. The
 * reducer is dispatching the user profile update
 * @param state - Initial State of the reducer
 * @param action - Reducer actions
 * ToDo move the reducer to the redux/reducers directory
 */
const reducer = (state: FirebaseAuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

/**
 * @description - Firebase Auth Context
 */
export const ContextFirebaseAuth = createContext<FirebaseAuthContext | null>(
  null
);

/**
 * @description - Firebase Auth Provider hook
 * @param children - Children components
 * @constructor - Adding listener for the Firebase Auth State
 */
export function FirebaseAuthProvider({ children }: { children: ReactNode }) {
  // State of the Firebase Auth
  const [state, dispatch] = useReducer(reducer, initialState);
  // Notification bar
  const { enqueueSnackbar } = useSnackbar();
  // User profile state
  const [profile, setProfile] = useState<TypeAccountProfile | undefined>();
  const [publicProfile, setPublicProfile] = useState<TypeAccountProfile | undefined>();
  const [employeeProfile, setEmployeeProfile] = useState<TypeEmployeeProfile | undefined>();
  const [companies, setCompanies] = useState<string[] | undefined>();
  const [admin, setAdmin] = useState<string[] | undefined>();
  // Local storage data for the selected company info
  // getting the company profile;
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', undefined);

  /**
   * @description - The current Firebase Auth instance, that was Initialized in the
   * index.ts level. The method getFirebase is returning that Initialized instance
   */
  const authInstance = getAuth();

  // ToDo add employee db profile listener
  useEffect(() => {
    if (selectedCompany && profile) {
      getUserEmployeeProfile(
        profile.uid,
        selectedCompany.user_uid,
        (fetchedProfile) => fetchedProfile && setEmployeeProfile(fetchedProfile),
        (errorMessage) =>
          enqueueSnackbar(`Login error - Can't get company employment info "${errorMessage}"`, { variant: 'error' })
      );
    }
  }, [selectedCompany, profile]);

  // UseEffect Listener for the Firebase Auth State
  useEffect(
    () =>
      onAuthStateChanged(authInstance, (user) => {
        if (user && user.uid && typeof user?.metadata?.lastSignInTime === 'string') {
          const lastSignedInDate = Date.parse(user.metadata.lastSignInTime);
          const currentDate = Date.now();
          const lastSignInDifferenceInDays = (currentDate - lastSignedInDate) / (60 * 60 * 1000 * 24);
          // Sign out if the last sign in is older than 7 days
          if (lastSignInDifferenceInDays > 7) {
            logout();
          } else {
            getUserPrivateProfile(
              user.uid,
              (fetchedProfile) => fetchedProfile ? setProfile(fetchedProfile) : setProfile({
                uid: user.uid,
                user_uid: user.uid,
                email: user.email || '',
                account_type: 'person',
                status: 'suspended',
                phone_number: null,
                display_name: '',
                first_name: '',
                last_name: '',
                middle_name: null,
                photo_url: null,
                created: Timestamp.now(),
                updated: Timestamp.now(),
              }),
              (errorMessage) =>
                enqueueSnackbar(`Login error - can't get user data "${errorMessage}"`, { variant: 'error' })
            );
            user.getIdTokenResult()
              .then((idTokenResult) => {
                const companiesUidList = idTokenResult?.claims?.companies as string[];
                setCompanies(companiesUidList ??  []);
                const companyUid: string = companiesUidList?.[0] ?? 'none';
                getCompanyProfileByUid(
                  companyUid,
                  setSelectedCompany,
                  (errorMessage) =>
                    enqueueSnackbar(`Login error - can't get selected company info "${errorMessage}"`, { variant: 'error' })
                )
                setAdmin(idTokenResult?.claims?.admin as string[] ?? []);
              });
            // Updating global state of user authenticated status
            dispatch({
              type: Initialisation.Initial,
              payload: {isAuthenticated: true, user}
            });
          }
        } else {
          // Updating global state of user authenticated status
          dispatch({
            type: Initialisation.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const getActionCodeSetting = () => {
    return {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'http://localhost:8111/apps?cartId=1234',
      // This must be true.
      handleCodeInApp: true,
      // ToDo add application level support
      iOS: {
        bundleId: 'com.example.ios'
      },
      android: {
        packageName: 'com.example.android',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'example.page.link'
    };
  };

  /**
   * @description - The method is executing sign in via email link using
   * firebase's auth with sendSignInLinkToEmail
   * @param email
   */
  const signWithEmailLink = (email: string) =>
    sendSignInLinkToEmail(authInstance, email, getActionCodeSetting())
      .then(() => {
        console.log('successfully sent');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });

  /**
   * @description - Firebase Login Method, authenticate user with email and pass
   * @param email - User email
   * @param password - User password
   */
  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(authInstance, email, password)
      .then((userCredential) => {
        // ToDo handle user authed
      })
      .catch((error) => {
        enqueueSnackbar(`Login error - Email/Password incorrect "${error.message}"`, { variant: 'error' });
      });

  /**
   * @description - Firebase Register Method, creates user with email and pass
   * @param email - User email
   * @param password - User password
   * @param firstName - User First Name
   * @param lastName - User Last Name
   */
  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    createUserWithEmailAndPassword(authInstance, email, password)
      .then((userCredentials) => {
        if (userCredentials?.user?.uid) {
          // Defining the users database reference
          // Writing user info into db
          // ToDo add the method
          // setDoc(userDbReference, {
          //
          // })
        } else {
          // ToDO handle the error
        }
      })
      .catch((error) => {
        // ToDO handle the error
      });

  const logout = async () => {
    await authInstance.signOut();
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(authInstance, email)
      .then(() => {
        // ToDo handle reset password
      })
      .catch((error) => {
        // ToDo handle reset password
      });
  };

  /**
   * @description - The method updating user data
   * @param data
   */
  const updateProfile = (data: Record<string, any>) => {
    if (profile?.uid) {
      updateUserByUid(
        profile.uid,
        data,
        () => enqueueSnackbar('Profile updated successfully', { variant: 'success' }),
        (error) =>
          enqueueSnackbar(`Login error - Email/Password incorrect "${error}"`, { variant: 'error' })
      );
    }
  }

  const auth = { ...state.user };

  /**
   * @description - The view of the Firebase Auth Context Hook method
   */
  return (
    <ContextFirebaseAuth.Provider
      value={{
        ...state,
        user: profile,
        userProfile: publicProfile,
        companies,
        admin,
        employeeProfile,
        login,
        signWithEmailLink,
        register,
        logout,
        resetPassword,
        // ToDo update user profile updating method
        updateProfile,
      }}
    >
      {children}
    </ContextFirebaseAuth.Provider>
  );
}
