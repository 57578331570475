/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getDocs, getDoc, serverTimestamp, updateDoc, setDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
// Queries
import { queryFetchDepartments, getDepartmentReference, getNewDepartmentReference, getNewDepartmentDocReference } from './queries';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The department type to handle the department data
 */
export type Department = {
  display_name: string,
  uid: string,
  manager_uid: string,
  members_count: number,
  status: string,
  updated: Timestamp
};

/**
 * @description - The department type to handle the department data
 */
export type DepartmentStatus = 'active' | 'suspended' | 'removed' | 'all';

/**
 * @description - The method is fetching departments list data for the company
 * @param company_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchDepartmentsForCompany(
  company_uid: string,
  onSuccess: (departments: Department[]) => void,
  onFail: (error: Error | string) => void,
) {
  // Generating query
  const queryAllDepartmentsByCompanyUid = queryFetchDepartments(company_uid);
  if (queryAllDepartmentsByCompanyUid) {
    getDocs(queryAllDepartmentsByCompanyUid)
      .then((departmentsSnapshots) => {
        const departments: Department[] = [];
        departmentsSnapshots.forEach((departmentSnapshot) => {
          const departmentData = departmentSnapshot.data();
          if (departmentData) {
            departments.push({
              uid: departmentData.uid,
              display_name: departmentData.display_name,
              manager_uid: departmentData.manager_uid,
              members_count: departmentData.members ?? 0,
              status: departmentData.status ?? 'active',
              updated: departmentData.updated,
            });
          }
        });
        onSuccess(departments);
      })
      .catch(onFail);
  }
}

export function fetchDepartmentByUid(
  department_uid: string,
  onSuccess: (department: Department) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getDepartmentReference(department_uid);
  getDoc(queryDetails)
    .then((departmentSnapshot) => {
      const departmentServerData = departmentSnapshot.data();
      if (departmentServerData) {
        onSuccess({
          display_name: departmentServerData.display_name,
          uid: departmentServerData.uid,
          manager_uid: departmentServerData.manager_uid,
          members_count: departmentServerData.members_count || 0,
          status: departmentServerData.status || 'active',
          updated: departmentServerData.updated,
        })
      }
    })
    .catch((error) => onFail(error.message))
}

export function updateDepartmentDataByUid(
  department_uid: string,
  departmentData: Partial<Department>,
  onSuccess: (department: Partial<Department>) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getDepartmentReference(department_uid);
  updateDoc(queryDetails, {
    ...departmentData,
    updated: serverTimestamp(),
  }).then(() => onSuccess(departmentData)).catch((fail) => { console.log(fail); onFail(fail); });
}

export function createDepartmentByCompanyUid(
  companyUid: string,
  departmentData: Partial<Department>,
  onSuccess: (departmentUid: string) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getNewDepartmentDocReference();
  setDoc(queryDetails, {
    ...departmentData,
    uid: queryDetails.id,
    members_count: 1,
    status: 'active',
    created: serverTimestamp(),
    updated: serverTimestamp(),
    company_uid: companyUid,
    active: true,
    entity_type: 'department',
  }).then(() => onSuccess(queryDetails.id)).catch((fail) => { onFail(fail) });
}
