/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getDocs,
  getDoc,
  Timestamp,
  addDoc,
  updateDoc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
// Queries
import {
  queryAllDocsByProjectUid,
  queryAllDocsByCompanyUid,
  queryDocByUid,
  queryAllDocumentsByDocUid,
  queryDocumentByUid,
  queryDocumentContentsByUid,
} from './queries';
import { fetchTotalNumberByCustomFields } from 'src/services/common';
// Local type
import {
  Doc,
  DocCategory,
  DocumentStatus,
  toDoc,
  Document,
  toDocument,
  DocumentType,
  DocumentContent,
  DocumentRequisite,
  DocumentHeader,
  DocumentFooter,
  toDocumentContent,
} from './models';
import { documentsPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is triggering the total document number fetching process
 * @param company_uid
 * @param docCategory
 * @param onSuccess
 * @param onFail
 * @param active
 */
export function fetchDocsTotalNumberByCompanyUid(
  company_uid: string,
  docCategory: DocCategory,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: string) => void,
  active = true,
) {
  fetchTotalNumberByCustomFields(
    company_uid,
    [
      [documentsPaths.FIELD_CATEGORY, docCategory],
    ],
    documentsPaths.COLLECTION_DOCS,
    onSuccess,
    onFail,
    active,
  );
}

/**
 * @description - The method is fetching the docs list based on company uid
 * @param user_uid - The user uid that do the fetching
 * @param company_uid - The company uid which docs need to be fetched
 * @param docCategory - The doc category type which need to be fetched
 * @param onSuccess - The on success method which need to be triggered when docs fetched
 * @param onFail - The on fail method which need to be triggered when something went wrong
 * @param documentStatus - The document status, if it needs custom ordering and filtering
 * @param limitNumber - The paginated total number of each page
 * @param nextPage - The paginated data's page number
 * @param orderField - The order field name, if it needs custom ordering
 * @param orderDirection - The order direction
 * @param active - The document activity status
 */
export function fetchAllDocsByCompanyUid(
  user_uid: string,
  company_uid: string,
  docCategory: DocCategory,
  onSuccess: (docs: Doc[]) => void,
  onFail: (error: string) => void,
  documentStatus?: DocumentStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
) {
  getDocs(queryAllDocsByCompanyUid(
    user_uid,
    company_uid,
    docCategory,
    documentStatus,
    limitNumber,
    nextPage,
    orderField,
    orderDirection,
    active,
  ))
    .then((querySnapshots) => {
      const docs: Doc[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          docs.push(toDoc(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(docs);
    })
    .catch((error) => onFail(error.message))
}

export function fetchDocByDocUid(
  docUid: string,
  onSuccess: (doc: Doc) => void,
  onFail: (error: string) => void,
) {
  const documentReference = queryDocByUid(docUid);

  getDoc(documentReference)
    .then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(toDoc(snapshot.data()));
      } else {
        onFail("Can't find the document");
      }
    })
    .catch(onFail)
}

export function fetchAllDocumentsByDocUid(
  docUid: string,
  onSuccess: (documents: Document[]) => void,
  onFail: (error: string) => void,
  docCategory?: DocCategory,
  documentStatus?: DocumentStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
  documentType?: DocumentType,
) {
  getDocs(queryAllDocumentsByDocUid(docUid, documentStatus, limitNumber, nextPage, active, documentType))
    .then((querySnapshots) => {
      const allDocuments: Document[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          allDocuments.push(toDocument(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(allDocuments);
    })
    .catch((error) => onFail(error.message));
}

export function fetchDocumentByDocumentUid(
  companyUid: string,
  docUid: string,
  documentUid: string,
  // ToDo fix any
  onSuccess: (documentData: {
      document: Document,
      contents: DocumentContent[],
      requisites: DocumentRequisite[],
      headers: DocumentHeader[],
      footers: DocumentFooter[],
    }) => void,
  onFail: (error: string) => void,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  documentStatus?: DocumentStatus,
  docCategory?: DocCategory,
  documentType?: DocumentType,
  active?: boolean,
) {
  // ToDo add filtering
  const allQueries = queryDocumentContentsByUid(docUid, documentUid, companyUid);
  const documentQuery = queryDocumentByUid(docUid, documentUid);
  Promise
    .all([
      getDoc(documentQuery),
      getDocs(allQueries.contents).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.requisites).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.headers).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.footers).catch((error) => { console.log(error); return null; }),
    ])
    .then(([
      documentSnapshot,
      contentsSnapshots,
      requisitesSnapshots,
      headersSnapshots,
      footersSnapshots,
    ]) => {
      if (documentSnapshot.exists()) {
        const document = toDocument(documentSnapshot.data());
        const contents: DocumentContent[] = [];
        const requisites: DocumentRequisite[] = [];
        const headers: DocumentHeader[] = [];
        const footers: DocumentFooter[] = [];
        contentsSnapshots?.forEach((snapshot) => {
          contents.push(toDocumentContent(snapshot.data()));
        });
        requisitesSnapshots?.forEach((snapshot) => {
          requisites.push(toDocumentContent(snapshot.data()));
        });
        headersSnapshots?.forEach((snapshot) => {
          headers.push(toDocumentContent(snapshot.data()));
        });
        footersSnapshots?.forEach((snapshot) => {
          footers.push(toDocumentContent(snapshot.data()));
        });
        onSuccess({
          document,
          contents,
          requisites,
          headers,
          footers,
        })
      } else {
        onFail("Can't fetch the document")
      }
    })
    .catch(onFail);
}
