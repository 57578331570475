/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  collection,
  doc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
// Paths
import { documentsPaths, DEFAULT_NUMBER_OF_DATA } from 'src/configs';
// Converters
import { DocCategory, DocumentStatus, DocumentStatusEnum, DocumentType } from 'src/services/documents/models';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is generating firestore query in order to fetch contracts/acts/documents from appropriate
 * collection.
 * @param companyUid - The company uid which project is related to.
 * @param projectUid - The project uid which documents should be selected.
 * @param userUid - The user uid which is doing the request
 * @param docCategory - The document type (contract/template/document) which need to fetch
 * @param documentStatus - The document status type - Template/Processing/Archive/Deleted
 * @param limitNumber - The result limit in order to make it faster
 * @param nextPage - The pagination
 * @param orderField - The order field in string that need to be fetched
 * @param orderDirection - The direction of the order
 * @param active - The document status property
 */
export function queryAllDocsByProjectUid(
  userUid: string,
  companyUid: string,
  projectUid: string,
  docCategory: DocCategory,
  documentStatus: DocumentStatus = DocumentStatusEnum.all,
  limitNumber: number = DEFAULT_NUMBER_OF_DATA,
  nextPage?: QueryDocumentSnapshot,
  orderField:
    | typeof documentsPaths.FIELD_DISPLAY_NAME
    | typeof documentsPaths.FIELD_CREATED
    | typeof documentsPaths.FIELD_PROJECT_UID
    | typeof documentsPaths.FIELD_STATUS = documentsPaths.FIELD_CREATED,
  orderDirection: 'asc' | 'desc' = 'desc',
  active = true,
) {
  // Returning the query
  let docsQuery = query(
    collection(getFirestore(), documentsPaths.COLLECTION_DOCS),
    where(documentsPaths.FIELD_ACTIVE, '==', active),
    // Checking company based documents
    where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking project based documents
    where(documentsPaths.FIELD_PROJECT_UID, '==', projectUid),
    // ToDo add permission based query (permission groups)
    // where()
    // Checking the category type
    where(documentsPaths.FIELD_CATEGORY, '==', docCategory),
    // Ordering by created date
    orderBy(orderField, orderDirection),
    // If no limit provided the default is 150
    limit(limitNumber),
  );
  if (documentStatus && documentStatus !== DocumentStatusEnum.all) {
    docsQuery = query(docsQuery, where(documentsPaths.FIELD_STATUS, '==', documentStatus));
  }
  // The paginated result
  if (nextPage) {
    docsQuery = query(docsQuery, startAfter(nextPage));
  }
  return docsQuery;
}

/**
 * @description - The method is generating firestore query in order to fetch contracts/acts/documents from appropriate
 * collection.
 * @param companyUid - The company uid which project is related to.
 * @param userUid - The user uid which is doing the request
 * @param docCategory - The document category type that need to be fetched
 * @param documentStatus - The document status type
 * @param limitNumber - The result limit in order to make it faster
 * @param nextPage - The pagination
 * @param orderField - The field order that need to be parsed
 * @param orderDirection - The order by direction
 * @param active - the document status
 */
export function queryAllDocsByCompanyUid(
  userUid: string,
  companyUid: string,
  docCategory: DocCategory,
  documentStatus: DocumentStatus = DocumentStatusEnum.all,
  limitNumber: number = DEFAULT_NUMBER_OF_DATA,
  nextPage?: QueryDocumentSnapshot,
  orderField:
    | typeof documentsPaths.FIELD_DISPLAY_NAME
    | typeof documentsPaths.FIELD_CREATED
    | typeof documentsPaths.FIELD_PROJECT_UID
    | typeof documentsPaths.FIELD_STATUS = documentsPaths.FIELD_CREATED,
  orderDirection: 'asc' | 'desc' = 'desc',
  active = true,
) {
  // Returning the query
  let docsQuery = query(
    collection(getFirestore(), documentsPaths.COLLECTION_DOCS),
    where(documentsPaths.FIELD_ACTIVE, '==', active),
    // Checking company based documents
    where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // ToDo add permission based query (permission groups)
    // where()
    // Checking the category type
    where(documentsPaths.FIELD_CATEGORY, '==', docCategory),
    // Ordering by created date
    orderBy(orderField, orderDirection),
    // If no limit provided the default is 150
    limit(limitNumber),
  );
  if (documentStatus && documentStatus !== DocumentStatusEnum.all) {
    docsQuery = query(docsQuery, where(documentsPaths.FIELD_STATUS, '==', documentStatus));
  }
  // The paginated result
  if (nextPage) {
    docsQuery = query(docsQuery, startAfter(nextPage));
  }
  return docsQuery;
}

/**
 * @description - The method is returning the document query path by uid
 * @param docUid - The selected document's uid
 */
export function queryDocByUid(docUid: string) {
  return doc(getFirestore(), documentsPaths.COLLECTION_DOCS, docUid);
}

/**
 * @description - The method is returning the query for the documents list
 * @param docUid - The top doc UID from which documents need to be fetched
 * @param documentStatus - The document status
 * @param limitNumber - The content limit
 * @param nextPage - The data pagination number
 * @param active - The document status
 * @param documentType - The document type, if it is needed to query based on type
 */
export function queryAllDocumentsByDocUid(
  docUid: string,
  documentStatus: DocumentStatus = DocumentStatusEnum.all,
  limitNumber: number = DEFAULT_NUMBER_OF_DATA,
  nextPage?: QueryDocumentSnapshot,
  active = true,
  documentType?: DocumentType,
) {
  // Returning the query
  let documentsQuery = query(
    collection(getFirestore(), documentsPaths.COLLECTION_DOCS, docUid, documentsPaths.SUB_COLLECTION_DOCUMENTS),
    // Active documents
    where(documentsPaths.FIELD_ACTIVE, '==', active),
    // ToDo add permission based query (permission groups)
    // where()
    // Ordering by created date
    orderBy(documentsPaths.FIELD_ORDER, 'asc'),
    // If no limit provided the default is 150
    limit(limitNumber),
  );
  if (documentStatus && documentStatus !== DocumentStatusEnum.all) {
    documentsQuery = query(documentsQuery, where(documentsPaths.FIELD_STATUS, '==', documentStatus));
  }
  if (documentType) {
    documentsQuery = query(
      documentsQuery,
      where(documentsPaths.FIELD_TYPE, '==', documentType),
    );
  }
  if (nextPage) {
    documentsQuery = query(documentsQuery, startAfter(nextPage));
  }

  return documentsQuery;
}

/**
 * @description - The method is returning the document query path by uid
 * @param docUid - The selected document's uid
 * @param documentUid - The selected document uid
 */
export function queryDocumentByUid(docUid: string, documentUid: string) {
  return doc(getFirestore(), documentsPaths.COLLECTION_DOCS, docUid, documentsPaths.SUB_COLLECTION_DOCUMENTS, documentUid);
}

/**
 * @description - The method is generating queries for the selected document contents
 * @param docUid - The top doc uid which content should be fetched
 * @param documentUid - The document uid which content should be fetched
 * @param companyUid
 * @param active - The document active switcher
 */
export function queryDocumentContentsByUid(
  docUid: string,
  documentUid: string,
  companyUid: string,
  active = true,
) {
  const reference = (contentCategory: string) => collection(
    getFirestore(),
    documentsPaths.COLLECTION_DOCS,
    docUid,
    documentsPaths.SUB_COLLECTION_DOCUMENTS,
    documentUid,
    contentCategory,
  );
  return ({
    contents: query(
      reference(documentsPaths.SUB_COLLECTION_CONTENTS),
      where(documentsPaths.FIELD_ACTIVE, '==', active),
      where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
      orderBy(documentsPaths.FIELD_ORDER, 'asc'),
    ),
    requisites: query(
      reference(documentsPaths.SUB_COLLECTION_REQUISITES),
      where(documentsPaths.FIELD_ACTIVE, '==', active),
      where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
      orderBy(documentsPaths.FIELD_ORDER, 'asc'),
    ),
    headers: query(
      reference(documentsPaths.SUB_COLLECTION_FOOTERS),
      where(documentsPaths.FIELD_ACTIVE, '==', active),
      where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
      orderBy(documentsPaths.FIELD_ORDER, 'asc'),
    ),
    footers: query(
      reference(documentsPaths.SUB_COLLECTION_HEADERS),
      where(documentsPaths.FIELD_ACTIVE, '==', active),
      where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
      orderBy(documentsPaths.FIELD_ORDER, 'asc'),
    ),
  })
}

export function getNewDocumentReference() {
  return collection(getFirestore(), 'newTemplateRequests');
}
