import { PATH_APP_REQUESTS } from './index';

// Icons
import {
  icon_elements,
  icon_forms,
  icon_user,
  icon_budgets,
  icon_payments,
  icon_invoices,
  icon_invoice,
  icon_box,
  icon_stock,
  icon_requests,
  icon_authorizations,
  icon_documents,
  icon_archive,
  icon_charts,
  icon_reports2,
  icon_analytics,
  icon_store,
  icon_team,
  icon_department,
  icon_process,
  icon_procurement,
} from '../components';

export default [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_APP_REQUESTS.dashboardFull,
        icon: icon_elements
      }
    ]
  },
  {
    subheader: 'requests',
    items: [
      {
        title: 'my requests',
        path: PATH_APP_REQUESTS.requests.selfFull,
        icon: icon_user
      },
      // {
      //   title: 'manage',
      //   path: PATH_APP_REQUESTS.requests.manageFull,
      //   icon: icon_invoice
      // },
      {
        title: 'create',
        path: PATH_APP_REQUESTS.requests.createFull,
        icon: icon_requests
      }
    ]
  },
  // {
  //   subheader: 'reports',
  //   items: [
  //     {
  //       title: 'report',
  //       path: PATH_APP_REQUESTS.reports.reportFull,
  //       icon: icon_forms
  //     },
  //     {
  //       title: 'analytics',
  //       path: PATH_APP_REQUESTS.reports.analyticsFull,
  //       icon: icon_analytics
  //     },
  //   ]
  // },
]