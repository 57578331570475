/**
 * @description - The Procurement application. Contains Procurement dashboard to control all platforms.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useRoutes } from 'react-router-dom';
// Contexts
import { BisflowThemeProvider, FirebaseAuthProvider } from 'src/contexts';
// Local components
import {
  NotistackProvider,
  MotionLazyContainer,
  ProgressBarStyle,
  Settings,
  ScrollTop,
} from 'src/components';
// Local types
import { TypeRouteItem } from 'src/@types';
// Routes
import { procurementRoutes, authRoutes, mainRoutes } from 'src/routes';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - Application based related routes.
 */
const routes: TypeRouteItem[] = [ mainRoutes, authRoutes, procurementRoutes ];

/**
 * @description - Bisflow application's administrative view.
 * @constructor
 */
export default function BisflowRequestsApp() {
  return (
    <BisflowThemeProvider>
      {/* Notification provider */}
      <NotistackProvider>
        {/* Authentication provider */}
        <FirebaseAuthProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollTop />
            {/* ToDo implement the Google Analytics */}
            { useRoutes(routes) }
          </MotionLazyContainer>
        </FirebaseAuthProvider>
      </NotistackProvider>
    </BisflowThemeProvider>
  );
}
