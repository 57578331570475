/**
 * @description - The main typescript file which needed to execute the project. Here based on selected application, the
 * appropriate App will be loaded and built for the dev and production
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// Firebase - server side services
import { initializeApp } from 'firebase/app';
// Routing module
import { BrowserRouter } from 'react-router-dom';
// If we are doing anything asynchronous on the server, we need Helmet to encapsulate data on a per-request basis, this
// package does just that.
import { HelmetProvider } from 'react-helmet-async';
// Material UI
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// The PersistGate component is the recommended way to delay rendering until persistence is complete.
import { PersistGate } from 'redux-persist/integration/react';
// Redux and Store
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from './redux/store';
// The React Context that can detect the collapse state.
// ToDo investigate and add firebase crush analytics
import { CollapseDrawerProvider, SettingsProvider } from './contexts';
// Reporting tool,  @ToDo - need to implement the method
import reportWebVitals from './reportWebVitals';

// Applications
import { BisflowAdminApp, BisflowFinanceApp, BisflowRequestsApp, BisflowDocumentsApp, BisflowProcurementApp, ErebuniLanding } from './apps';

// Just imports
// The localization/translation
import './locales/i18n';
// ToDo - Need to add dev environment dependant highlighter. The highlighter should be used only when developing.
//  https://stackoverflow.com/questions/55022572/conditionally-import-assets-in-create-react-app
import './utils/highlight';
// scroll bar
import 'simplebar/src/simplebar.css';
// lightbox
import 'react-image-lightbox/style.css';
// map
import 'mapbox-gl/dist/mapbox-gl.css';
// editor
import 'react-quill/dist/quill.snow.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
// PDF viewer worker
import { Worker } from '@react-pdf-viewer/core';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - Initializing the firebase application
 */
const firebaseConfig = process.env.REACT_APP_FIREBASE_BISFLOW_ADMIN_APP_CREDENTIALS_PROD
  ? process.env.REACT_APP_FIREBASE_BISFLOW_ADMIN_APP_CREDENTIALS_PROD
  : '{}';
initializeApp(JSON.parse(firebaseConfig));

/**
 * @description - The method is getting the application from environment. It is simply reading the environment
 * variable that is defining the Application that is needed to deployed and returning its Node instance.
 */
const getApplication = () => {
  // Getting current app from environment variable
  const appName = process.env.REACT_APP_NAME;
  switch (appName) {
    case 'BISFLOW':
      return <BisflowAdminApp />;
    case 'ADMIN':
      return <BisflowAdminApp />;
    case 'FINANCE':
      return <BisflowFinanceApp />;
    case 'DOCUMENT':
      return <BisflowDocumentsApp />;
    case 'PROCUREMENT':
      return <BisflowProcurementApp />;
    case 'REQUEST':
      return <BisflowRequestsApp />;
    case 'EREBUNI':
      return <ErebuniLanding />;
    default:
      return <BisflowRequestsApp />;
  }
};

ReactDOM.render(
  // To highlight potential problems in an application
  <StrictMode>
    {/* Server and Client now requires <HelmetProvider> to encapsulate state per request */}
    <HelmetProvider>
      {/* Redux - Global state */}
      <ReduxProvider store={store}>
        {/* The PersistGate component is the recommended way to delay rendering until persistence is complete. */}
        <PersistGate loading={null} persistor={persistor}>
          {/* Translation/Localization provider */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* Settings Provider */}
            <SettingsProvider>
              {/* Collapse Drawer provider - ToDo analyse */}
              <CollapseDrawerProvider>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                  <BrowserRouter>
                    {getApplication()}
                  </BrowserRouter>
                </Worker>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
